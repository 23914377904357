import React, { useContext } from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"

import { GlobalStateContext } from '../../utils/GlobalContextProvider';
import logoVitaFons from '../../../static/logoVitaFons.jpg'
import spray from '../../../static/spray.jpg'
import StyledPageSprayFoliaire from './StyledPageSprayFoliaire';

export const PageSprayFoliaire = () => {
    const { pageSprayFoliaire } = useStaticQuery(
        graphql`
            query {
                pageSprayFoliaire:datoCmsPageSprayFoliaire {
                    encart1Node {
                        childMarkdownRemark {
                          html
                        }
                    }
                    encart2Node {
                        childMarkdownRemark {
                          html
                        }
                    }
                    encart3Node {
                        childMarkdownRemark {
                          html
                        }
                    }
                  }
            }
          `)
    const { titlePage } = useContext(GlobalStateContext)
    return (
        <StyledPageSprayFoliaire>
            <div className='container-page'>
                <div className='wrapper-titleh3'>
                    <h3>{titlePage}</h3>
                </div>
                <div className='container-img-txt'>
                    <img id='logo' alt='logo' src={logoVitaFons} />
                    <div className='container-txt1' dangerouslySetInnerHTML={{ __html: pageSprayFoliaire.encart1Node.childMarkdownRemark.html}} />
                </div>
                <div className='little-separator' />
                <div className='container-txt2' dangerouslySetInnerHTML={{ __html: pageSprayFoliaire.encart2Node.childMarkdownRemark.html}} />
                <div className='container-img-txt2'>
                    <div className='wrapper-img'>
                        <img id='img' alt='conditionneur' src={spray} />
                        <Link to='/' className='btnToHome'>
                            Voir les Produits
                        </Link>
                    </div>
                    <div className='container-txt3' dangerouslySetInnerHTML={{ __html: pageSprayFoliaire.encart3Node.childMarkdownRemark.html}} />
                </div>
                <p className='container-note'>
                    Le <Link to='/conditionneurdesols'>Conditionneur de Sols</Link> et le <Link to='/sprayfoliaire'>Spray Foliaire</Link> Vita Fons II sont complémentaires.
                </p>
            </div>
        </StyledPageSprayFoliaire>
    )
}
