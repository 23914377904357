import styled from 'styled-components'

const StyledPageSprayFoliaire = styled.section`
    width: 100vw;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;

        .container-page {
            max-width: 1384px;
            padding: 0 24px;
        }

        h3 {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            color: #000000;
            padding: 24px 0 48px 0;
        }
        h4 {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 21px;
            line-height: 25px;
            color: #000000;
            padding: 0 0 24px 0;
        }

        .container-txt1 {
            width: 100%;
            height: auto;
            margin-bottom: 24px;
            position: relative;
        }

        .container-note {
            width: 100%;
            text-align: center;
            font-weight: bold;
        }

        #logo {
            padding-left: 24px;
            float: right;
            align-self: flex-end;
            height: 100px;
        }

        .little-separator {
            width: 261px;
            height: 6px;
            background: #4F324F;
            margin: 48px 0;
        }

        ol {
            list-style: decimal;
            list-style-position: inside;
        }

        em {
            display: initial;
            font-size: 14px;
        }

        p, li {
            padding: 12px 0;
        }
        li::marker {
            padding-left: 24px;
        }

        .container-txt2 {
            width: 100%;
            background: #F4E1F4;
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
            padding: 24px;
            margin-bottom: 48px;
        }

        .container-img-txt2 {
            width: 100%;
            height: auto;
            margin-bottom: 48px;
            position: relative;
            display: flex;
        }
        .wrapper-img {
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 35%;
        }
        #img {
            width: 100%;
            z-index: -1;
        }
        .container-txt3 {
            width: 65%;
            background: #F4E1F4;
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
            padding: 24px;
        }

        .btnToHome {
            margin-top: 24px;
            background: linear-gradient(180deg, #F4E1F4 0%, #B08DB0 100%);
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #FFFDF9;
            padding: 8px 24px;
            cursor: pointer;
            transition: 400ms;
            text-decoration: none;
        }

        .btnToHome:hover {
            transform: scale(1.06);
            transition: 400ms;
        }

        @media screen and (max-width: 700px) {
            h3 {
                font-size: 22px !important;
            }
            #img {
                width: 95%;
            }
            .container-img-txt2 {
                flex-direction: column;
                align-items: center;
            }
            .wrapper-img {
                width: 80%;
            }
            .btnToHome {
                margin-bottom: 24px;
            }
            .container-txt3 {
                width: 100%;
            }
        }
`

export default StyledPageSprayFoliaire